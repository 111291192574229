import "./sentHomeMessage.scss";

const SentHomeResponseMessage = ({ message }) => {
  return (
    <div className="fcm-message-container">
      <div className="fcm-avatar-container">
        <div>
          <img src="assets/logo/bunny.png" />
        </div>
      </div>
      <div className="fcm-message-body">
        <span>Clipboard</span>
        <div className="message-wrapper">
          <div>
            <p>{message.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SentHomeResponseMessage };
