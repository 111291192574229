import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonItemDivider, IonRow } from "@ionic/react";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import { Chip, Stack } from "@mui/material";
import { useWorkerQualifications } from "@src/app/licenseManager/hooks/useWorkerQualifications";
import { logEvent } from "@src/appV2/lib/analytics";
import { OpenShiftCalendarDistanceFilterDialog } from "@src/appV2/OpenShifts/components/OpenShiftCalendar/Dialogs/DistanceFilterDialog";
import { OpenShiftCalendarLicenseFilterDialog } from "@src/appV2/OpenShifts/components/OpenShiftCalendar/Dialogs/LicenseFilterDialog";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { SEARCH_MODE, USER_EVENTS } from "@src/constants";
import pluralize from "pluralize";
import { useMemo } from "react";

import { SHIFTS_SUMMARY_HEADER_TEXT } from "./constants";
import { ShiftFiltersInterface } from "./interface";

export function ShiftFilters(props: ShiftFiltersInterface) {
  const {
    totalShifts,
    searchMode,
    updateOpenShifts,
    isUrgentShiftFilter,
    facilityShiftIsLoading,
    distancePreference,
    qualificationPreference,
    setFilters,
    agentLicenses,
  } = props;

  const distancePreferenceModalState = useModalState();
  const licensePreferenceModalState = useModalState();

  const updateWorkerCache = useUpdateWorkerCache();

  const workerQualifications = useWorkerQualifications();

  const shiftsSummaryHeader = useMemo(() => {
    let summaryText = "";

    switch (searchMode) {
      case SEARCH_MODE.MAP:
        if (facilityShiftIsLoading) {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.LOADING_SHIFTS;
        } else if (!facilityShiftIsLoading && totalShifts !== 0) {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_FOR_NEXT_30_DAYS;
        } else {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_FOR_NEXT_30_DAYS;
        }
        break;
      case SEARCH_MODE.HCF_PROFILE:
        if (facilityShiftIsLoading) {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.LOADING_SHIFTS;
        } else if (!facilityShiftIsLoading && totalShifts === 0) {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_RIGHT_NOW;
        } else if (workerQualifications.length > 1) {
          summaryText = SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_FOR;
        }
        break;

      default:
        summaryText =
          totalShifts !== 0
            ? SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_WITHIN
            : SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_WITHIN;
    }

    return `${summaryText} `;
  }, [totalShifts, searchMode, workerQualifications, facilityShiftIsLoading]);

  const openDistancePreferenceModal = () => {
    distancePreferenceModalState.openModal();
    logEvent(USER_EVENTS.OPEN_DISTANCE_PREFERENCE_MODAL, {
      distance: distancePreference,
    });
  };

  const openQualificationPreferenceModal = () => {
    licensePreferenceModalState.openModal();
    logEvent(USER_EVENTS.QUALIFICATION_PREFERENCE_EDITED, {
      qualification: qualificationPreference,
      source: searchMode,
    });
  };

  return (
    <>
      <IonRow
        className="ion-justify-content-center ion-align-items-center"
        style={{ cursor: "pointer" }}
      >
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
          <Text variant="body2" whiteSpace="nowrap">
            {shiftsSummaryHeader}
          </Text>
          {![SEARCH_MODE.HCF_PROFILE, SEARCH_MODE.MAP].includes(searchMode) && (
            <Chip
              color="primary"
              icon={<FilterIcon />}
              aria-label="Update Distance"
              label={
                <b>
                  {distancePreference} {pluralize("mile", distancePreference)}
                </b>
              }
              disabled={!isDefined(distancePreference)}
              onClick={openDistancePreferenceModal}
            />
          )}
          {workerQualifications.length > 1 && (
            <Chip
              color="primary"
              icon={<FilterIcon />}
              aria-label="Update License"
              label={<b>{qualificationPreference || "Select Qualification"}</b>}
              disabled={!isDefined(qualificationPreference)}
              onClick={openQualificationPreferenceModal}
            />
          )}
        </Stack>
        {!isUrgentShiftFilter && (
          <IonItemDivider
            style={{
              minHeight: "0",
            }}
          />
        )}
      </IonRow>

      {searchMode === SEARCH_MODE.HCF_PROFILE && <div className="h-line"></div>}

      <OpenShiftCalendarDistanceFilterDialog
        modalState={distancePreferenceModalState}
        onSubmit={(formData) => {
          setFilters({ distance: formData.distance, license: qualificationPreference ?? "" });

          updateOpenShifts?.();

          // We update filters using useWorkerPreferences but explicitly updating the worker cache as well
          updateWorkerCache({
            preference: {
              distance: formData.distance,
              qualification: qualificationPreference ?? "",
            },
          });

          logEvent(USER_EVENTS.UPDATED_DISTANCE_PREFERENCE, {
            distance: formData.distance,
          });

          distancePreferenceModalState.closeModal();
        }}
        defaultValues={{ distance: distancePreference }}
      />

      <OpenShiftCalendarLicenseFilterDialog
        modalState={licensePreferenceModalState}
        agentLicenses={agentLicenses}
        defaultValues={{
          license: qualificationPreference ?? "",
        }}
        onSubmit={(formData) => {
          setFilters?.({ distance: distancePreference, license: formData.license });

          // We update filters using useWorkerPreferences but explicitly updating the worker cache as well
          updateWorkerCache({
            preference: {
              distance: distancePreference,
              qualification: formData.license,
            },
          });

          updateOpenShifts?.();

          logEvent(USER_EVENTS.QUALIFICATION_PREFERENCE_UPDATED, {
            qualification: formData.license,
          });
        }}
      />
    </>
  );
}
